
// my color

$pink : #ea7a80;
$navy : #42779e;
$lite-kaki:#eaf1f2;
$brown : #b05a0c;

$tbud-gothic : 'TBUDゴシック R','TBUDGothic R';
$udshingo : 'UD新ゴ コンデンス90 M','UD Shin Go Conde90 M';
$mb101 : 'ゴシックMB101 B','Gothic MB101';
$shingo-b : '新ゴ B','Shin Go Bold';
$maru-fork : '丸フォーク M','Maru Folk Medium';

/* モリサワウェブフォント
リュウミン R-KL	Ryumin Regular KL
リュウミン B-KL	Ryumin Bold KL
黎ミン M	Reimin Medium
A1明朝	A1 Mincho
新ゴ R	Shin Go Regular
新ゴ B	Shin Go Bold
ゴシックMB101 B	Gothic MB101
見出しゴMB31	Midashi Go MB31
中ゴシックBBB	Gothic Medium BBB
じゅん 201	Jun 201
じゅん 501	Jun 501
新丸ゴ R	Shin Maru Go Regular
丸フォーク M	Maru Folk Medium
フォーク M	Folk Medium
シネマレター	Cinema Letter
はるひ学園	Haruhi Gakuen
G2サンセリフ-B	GSanSerif-B
那欽	Nachin
竹 B	Take Bold
新ゴ シャドウ	Shin Go Shadow
ぶらっしゅ	Brush-U
トーキング	Talking
すずむし	Suzumushi
教科書ICA M	Kyoukasho ICA Medium
陸隷	Likurei
TB古印体	Kointai-M
TBUDゴシック R	TBUDGothic R
TBUDゴシック E	TBUDGothic E
UD新ゴ コンデンス90 L	UD Shin Go Conde90 L
UD新ゴ コンデンス90 M	UD Shin Go Conde90 M
*/



.logo{
	display: block;
	margin-bottom: rem-calc(10);
}
.sitename{
	// margin-top:1rem;
	font-size:2rem;
	line-height: 1;
}
.sitecaption{
	color:$brown;
	font-size:.7rem;
	line-height: rem-calc(16);
}
.contactus{
	margin:1.2rem 0 0;
	text-align: right;
	color:$alert-color;
	// font-size:rem-calc(18);
	line-height: 1;
	.phonenum{
		font-family: $shingo-b,sans-serif;
		em{
			font-style: normal;
			font-size:rem-calc(22);
		}

	}
	.append{
		font-size:rem-calc(12);
	}
	.append{
		color:$brown;
	}
}

@include breakpoint(small only){
	.sitename{
		margin-top:rem-calc(10);
	}
	.logo{
		margin-left:rem-calc(10);
		>img{
			max-height:40px;
			width:auto;
		}
	}

	// display: block;
	.contactus{
		.phonenum{
			em{
				font-size:1rem;
			}
		}
	}
}

@include breakpoint(medium){
	.site-header{
		margin-top:1rem;
	}
}
.globalnav{
	font-size:rem-calc(14);
	.menu{
		a{
			padding-top:rem-calc(8);
			padding-bottom: rem-calc(8);
			color:$black;
			
		}
		.contactbtn{
			font-size:1rem;
			color:#fff;
		}
	}
	.has_menu{
		a{
			// &:hover{
			// 	color:#fff;
			// }
		}
	}
	.is-dropdown-submenu{
		background:rgba(255,255,255,0.9) !important;
	}
	.is-dropdown-submenu-item{
		&:hover{
			background:lighten($light-gray,3%);
			cursor: pointer;
		}
	}
}

.mobilenav{
	// position: absolute;
	// top:2rem;
	// right:.875rem;
	i{
		font-size:1.4rem;
	}
}
.spnav{
	position: absolute;
	top:0;
	right:0;
	margin:0;
	padding: 0;
	list-style: none;
	display: table;
	li{
		display: table-cell;
		width:60px;
		height:60px;
		text-align: center;
		vertical-align: middle;
		a{
			display: inline-block;
			height:60px;
		}
	}
}
.offcanvas-btn{
	margin-bottom:0;
	@include hamburger($primary-color, $secondary-color, 34px,22px,4px,3);
	background: none;
	color:$primary-color;
	font-size:rem-calc(11);
	span{
		position: absolute;
	    top: 25px;
	    left: 0px;
	}
}

.off-canvas{
	&.is-open{
		padding-top:3rem;
	}
}

.blank{
	background:#eee;
}
.blank-banner{
	height:290px;
}
.blank-col{
	margin-bottom: .8rem;
	min-height: 168px;
}

.sp-hero{
	margin:0;
	padding-top:40px;
	width:100%;
	height:220px;
	background:url(../images/home-sp-hero.jpg) center top no-repeat;
	background-size: cover;
	text-align: center;
	p{
		font-size:1rem;
		line-height: 2.4em;
		span{
			// display: inline-block;
			margin-bottom: 1rem;
			padding:4px 8px;
		background: rgba(255,255,255,0.8);
		}
	}
}

// main
// -----------------------------------------
.main{
	.main-content{
		padding-top:1rem;
		padding-bottom: 6rem;
		.page-header{
			margin-bottom:4rem;
			.page-title{
				padding-bottom:rem-calc(10);
				margin-bottom:1.4rem;
				border-bottom:1px dashed $primary-color;
				color:$primary-color;
			}
		}
		@include breakpoint(medium){
			padding-top:2rem;
			.page-header{

			}
		}
	}
	.group{
		//section
		margin-bottom:3.6rem;
	}
	.gr-bulk{
		margin-bottom: 1rem;
	}
	@include breakpoint(medium){
		border-top:1px solid $primary-color;
	}
}

h2{
	color:$brown;
	&.section-title{
		margin-bottom: 1.6rem;
		font-size:rem-calc(24);
		font-weight: 800;
	}
}

h3{
	&.op-item-title{
		margin-bottom:1.6rem;
		padding-bottom:.875rem;
		color:darken($primary-color,10%);
		font-weight: bold;
		text-align: center;
		border-bottom:1px solid darken($primary-color,10%);
	}
}

// common
//---------------------------------

.imgph{
	margin-bottom: 1rem;
}

.price{
	color:$alert-color;
	text-align: right;
	font-family: $shingo-b, $body-font-family;
	font-weight: 800;
}
.basic-price{
	font-size:rem-calc(24);

}
.op-price{
	font-size:rem-calc(20);
}
.padd{
	font-size:.875rem;
}
.preadd{
	margin-right:2em;
	color:$black;
	font-size:1rem;
}

// footer
// -----------------------------------------
.footer{
	padding:rem-calc(28) 1rem;
	border-top:1px solid $secondary-color;
	background: $lite-kaki;
	.com{
		float: left;
		margin-right:1rem;
		a{
			display: block;
			width:146px;
		height:40px;
		background: url(../images/comwork.png) 0 0 no-repeat;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		}
	}
	.footnav{
		font-size:.875rem;
		a{
			color:$black;
		}
	}
	.copyright{
		margin-top:1rem;
		font-size:rem-calc(12);
		text-align: center;
	}
}
// sidebar
// -----------------------------------------
.sidebar{
	padding-bottom: 6rem;
	@include breakpoint(medium){
		padding-top:32px;
	}
	.menu{
		margin-bottom: 1rem;
		>li{	
			>a{
				padding:rem-calc(20) 0;
				margin-left:1rem;
				margin-right:1rem;
				border-bottom: 1px solid $primary-color;
			}
			.submenu{
				//border-bottom: 1px solid $primary-color;
				li>a{
					border-bottom: none;
					padding:.7rem 1rem;
					//margin:0;
				}
			}
			&.is-accordion-submenu-parent{
				>a{
					border-bottom: none;
				}
			}
			&.is-accordion-submenu-parent+li{
				>a{
					border-top:1px solid $primary-color;
				}
			}
		}

	}
}

.banners{
	list-style: none;
	margin:0;
	text-align: center;
	li{
		margin:0 0 1rem;
		text-align: center;
	}
}

// home
// -----------------------------------------
.home-feature{
	margin-top:1rem;
	margin-bottom: 0;
}
.feat-banner{
	@include grid-col(12);
	@include breakpoint(medium){
		@include grid-col(6);
	}
}
.home-message{
	margin-bottom:1rem;
	padding-left:100px;
	color:$brown;
	background:url(../images/home-messege.jpg) center bottom no-repeat;
	background-size: cover;
	strong{
		color:$primary-color;
		font-size:rem-calc(20);
		font-weight: bold;
	}
	.morestrong{
		color:$alert-color;
	}
	@include breakpoint(medium){
		min-height: 290px;
		padding-left:316px;
		padding-top:30px;
		font-size:rem-calc(24);
		strong{
			font-size:rem-calc(32);
		}
		.pad-six-words{
			padding-left:6em;
		}
		.pad-ten-words{
			padding-left:10em;
		}
	}
}
.sect{
	margin: 5rem 0;
}

.ourserv{
	// margin: 5rem 0;
}
.services{
	a{
		color:#fff;
	}
	.service-col{
		position: relative;
		margin-bottom: .875rem;
		height:188px;
		overflow: hidden;
		background-position: 0 0 ;
		background-repeat:no-repeat;
		.categ{
		position: absolute;
		bottom: 0;
		left:0;
		height:4em;
		width:100%;
		padding:.5rem;
		background:rgba($primary-color, 0.8);
			
		}
		&:hover{

			.categ{
				-webkit-transition: color 0.5s ease-out, height .5s ease-in;
	    		transition: color 0.5s ease-out, height .5s ease-in;
	    		background:rgba($primary-color, 0.4);
	    		color:$primary-color;
	    		height:100%;
	    		font-weight: bold;
			}
				
			}
	}
	.col-air{background-image:url(../images/home-categ-aircon.jpg);}
	.col-kit{background-image:url(../images/home-categ-kitchen.jpg);}
	.col-fan{background-image:url(../images/home-categ-fan.jpg);}
	.col-bat{background-image:url(../images/home-categ-bath.jpg);}
	.col-dre{background-image:url(../images/home-categ-dresser.jpg);}
	.col-toi{background-image:url(../images/home-categ-toilet.jpg);}
	
}
.our-promises{
	position: relative;
	//margin-top:-12px;

	text-align: right;
	background:#00b7ee url(../images/home-promise.png) center top no-repeat;
	background-size: cover;
	min-height:420px;
	.promises{
		position:absolute;
		top:230px;
		right:10px;
		width:250px;
		min-height:160px;
		margin-bottom: 0;
		text-align: left;
		dt{
			color:$primary-color;
			font-size:rem-calc(18);
			line-height: 1;
		}
		dd{
			font-size:rem-calc(14);
		}
	}
	@include breakpoint(medium){
		min-height: 390px;
		.promises{
			top:168px; 
			left:75px;
			width:188px;
			height:218px;
		}
	}
	
}
.setmenu{
	margin-bottom: 2rem;
	background-color: $primary-color;
	color:#fff;
	a{
		display: block;
		padding:.8rem;
		color:#fff;
		&:hover{
			-webkit-transition: background-color 0.25s ease-out;
    		transition: background-color 0.25s ease-out;
			background-color:$navy;
		}
	}
	h3{
		padding-bottom: .4rem;
		font-size:rem-calc(24);
		border-bottom: 1px solid #fff;
		.setadd{
			font-size:1rem;
		}
	}
	p{
		margin-bottom: .5rem;
	}
	.setimg{
		padding-top:92px;
		background-position: center center;
		background-repeat:no-repeat;
		background-size: cover;
	}
}
.set-kitchen .setimg{background-image:url(../images/home-set-kitchen.jpg);}
.set-sanitaly .setimg{background-image:url(../images/home-set-sanitaly.jpg);}
.set-bathaircon .setimg{background-image:url(../images/home-set-bathaircon.jpg);}
.set-water4 .setimg{background-image:url(../images/home-set-water4.jpg);}
.set-water5 .setimg{background-image:url(../images/home-set-water5.jpg);}
.set-bathrenge .setimg{
	background-image:url(../images/home-set-bathrenge.jpg);
}
.price-bulk{
	padding-left:.25rem;
	padding-right:.25rem;
	color:$secondary-color;
	background: lighten($primary-color,48%);
	text-align: center;
	// border-left:1px solid $primary-color;
	// border-right:1px solid $primary-color;
}

//flow
//-----------------------------
.flow{
	li{
		&:after{
			display: block;
			color:$alert-color;
			font-family: 'Font Awesome 5 Free';
			content: "\f0d7";
			font-size:5rem;
			font-weight: 900;
			text-align: center;
			line-height: .6;
		}
		&:last-child{
			&:after{
				display: none;
			}
		}
	}
}

.flowcol{
	margin-bottom: rem-calc(30);
	padding:.875rem;
	border:1px solid $secondary-color;
	border-radius: 2px;
	.ico-num{
		margin-right:.4rem;
	}
	.flowaddimg{
		text-align: center;
	}
}

//flowの縦並び
.flowol,
.floworder{
	margin-left:0;
	list-style-type: none;
	li{
		.flowcol{
			margin-bottom: 0;
		}
	}
}
.flowol{
	li{
		&:after{
			content: "\f107";
			font-size:2rem;
			line-height: 4rem;
		}
	}
	.flowcol{
		>p{
			margin-left:1.4rem;
		}
	}
}

.floworder{
	.flowcol{
		border-radius: 0;
		border:none;
		h3{
			position: relative;
			height:4em;
			color:$alert-color;
			margin:-.875rem -.875rem .875rem;
			padding-top:1.4rem;
			padding-left:5em;
			.ico-step{
				position: absolute;
				top:0;
				left:.875rem;
				margin-top:-.3em;
				width:4em;
				line-height: 4em;
				border-radius:50%;
				background: $alert-color;
				color:#fff;
				text-align: center;
			}
		}
		>p{
			margin-bottom: 0;
		}
	}
	.flowaddimg{
		text-align: center;
	}
}

.smenu{
	margin-bottom: 1rem;
	padding:.875rem;
	border:1px solid $primary-color;
	.toilet &,
	.wroom &{
		border:none;
	}
	.smenu{
		.rangehood &,
		.kitchen &{
			border: none;
		}
	}
	h3{
		margin-bottom: 1rem;
		color:darken($primary-color,10%);
		font-weight: bold;
	}
}
.smenu-price{
	margin-bottom: 0;
	dt{
		padding:.5rem;
		border:1px solid $primary-color;
		line-height: 1;
		border-radius: .2rem;
	}
	dd{
		padding-bottom:rem-calc(14);
		padding-left:.5rem;
		padding-right:.5rem;
	}
}


//bath

.bathprice{
	@include breakpoint(medium){
		background:url(../images/bath-price-bg.jpg) center top no-repeat;
		.smenu{
			padding:rem-calc(5);
			border:none;
			dt{
				background: rgba(255,255,255,0.8);
			}
		}
	}
}

//kitchen
.kitprice{
	// @include breakpoint(medium){
	// 	background: url(../images/kitchen-price-bg.jpg) right top no-repeat;
	// }
}
.opmenu-kit{
	.imgph{
		text-align: center;
	}
}
.bulk-op-kit{
	text-align: center;
	
	.opmenu{
		margin-bottom: 0;
	}
	
}
.opset{
	padding:0 0 .875rem;
	border:1px solid $primary-color;
	text-align: center;
	p.title-pretend{
		margin-bottom: 0;
		line-height: 2.4em;
	}
	h3{
		padding:.5rem .4rem;
		background:lighten($primary-color,30%);
	}
	.arrow-down{
		color:lighten($alert-color,20%);
	}
}
.opset-include{
	display: table;
	width:100%;
	padding-left:.5rem;
	padding-right:.5rem;
	margin-bottom:.875rem;
	.opset-item,
	.ico{
		display: table-cell;
		vertical-align: middle;
	}
	.opset-item{
		
		background:$light-gray;
		dt,dd{
			text-align: center;
		}
	}
	.ico{
		padding-left:1rem;
		padding-right:1rem;
		.fa-plus{
			color:$alert-color;
		}
	}
}

.opset-total-price{
	margin-top:.875rem;
	font-size:1.2rem;
	font-weight: bold;
	.basic-price{
		font-size:2rem;
	}
}

//rangehood
.types{
	margin-bottom: 2rem;
	@include grid-row;
	@include breakpoint(medium){

		.typeph{
			@include grid-col(4);
		}
		.type-col{
			@include grid-col(8);
		}
	}
}



// company profile
//------------------------------------------
.profile{
	dd{
		margin-bottom: 1.5rem;
	}
	@include breakpoint(medium){
		dt{
			float: left;
			clear: left;
			width:8em;
			&.dt-expand{
				float: none;
				width:auto;
				// &+dd{
				// 	margin-left:0;
				// }
			}
		}
		dd{
			margin-left:8em;
			dl{
				margin:.8em 0;
			}
			dt{
				float: none;
				width:auto;
				max-width:15em;
				font-weight: normal;
				&::before{
					content:"■";
				}
			}
			dd{
				margin-left:1em;
				margin-bottom: .5rem;
			}
		}
	}
}

.basic-tbl{
	//width:auto;
	thead{
		th{
			text-align: center;
			&:first-child{
				border-right:1px solid $light-gray;
			}
		}
	}
	tbody{
		th{
			&:first-child{
				border-right:1px solid $light-gray;
			}
		}
		td{
			text-align: center;
		}
	}
}
.leftbase{
	th{
		
	}
	
	tbody{
		th{
			text-align: left;
			.andmore{
				text-align: right;
			}
			@include breakpoint(medium){
				padding-right:4em;
				width:24em;
			}
		}
		td{
			
		}
	}
	.list-price{
		text-align: right;
		@include breakpoint(medium){
			padding-right:10rem;
		}
	}
}


/* contact
----------------------------------------------- */
@include breakpoint(medium){
	.form-input{
		width:20em;
	}
	.form-txtarea{
		width:38em;
	}
}

.form-txtarea{
	height:16em;
}

@media print{
	a,
    a:visited { text-decoration: underline;}
    @if $print-hrefs {
      a[href]:after { content: ''; }
    }
    .home-message{
    	padding-left:0;
    }
}


.trademark{
	&.note{
		color:#b05a0c;
	}
	
	sub{
		font-size:.85em;
	}
}